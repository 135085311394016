import * as React from 'react';
import {Icon, Link, MessageBarType} from 'office-ui-fabric-react';
import {Util, Toast} from "@voxfp/opal_ui_common";
import { DashboardService } from '../services/dashboardService';
import Can from "@voxfp/opal_ui_common/dist/components/permissions/can";

export interface AppProps {
    type?: string;
    onItemClick: any;
}

export interface AppState {
    draftCount: number;
    awaitingReviewCount: number;
    toReviewCount: number;
}

export class DashboardItem extends React.Component<AppProps, AppState> {

    dashboardService: DashboardService = new DashboardService();

    constructor(props: any, context: any) {
        super(props, context);
        this.state = this.initializeState();
    }

    initializeState() {  
        return ({
            draftCount: null,
            awaitingReviewCount: null,
            toReviewCount: null
        });
    }

    componentDidMount() {
        if (this.props.type === "contentcontrol") {
            this.fetchContentControlStats();
        }
        if (this.props.type === "template") {
            this.fetchTemplateStats();
        }
        if (this.props.type === "document") {
            this.fetchDocumentStats();
        }
    }

    fetchContentControlStats() {
        this.dashboardService.fetchDashboardContentControlSummary()
        .then((DashboardContentControl: any) => {
            this.setState({
                draftCount: DashboardContentControl.data["draft_count"],
                awaitingReviewCount: DashboardContentControl.data["awaiting_review_count"],
                toReviewCount: DashboardContentControl.data["to_review_count"]
            });
        })
        .catch((error) => {
            Util.showToast(new Toast('Error fetching content control summaries. ' + error, MessageBarType.error));
        });
    }

    fetchTemplateStats() {
        this.dashboardService.fetchDashboardTemplateSummary()
        .then((DashboardTemplate: any) => {
            this.setState({
                draftCount: DashboardTemplate.data["draft_count"],
                awaitingReviewCount: DashboardTemplate.data["awaiting_review_count"],
                toReviewCount: DashboardTemplate.data["to_review_count"]
            });
        })
        .catch((error) => {
            Util.showToast(new Toast('Error fetching template summaries. ' + error, MessageBarType.error));
        });
    }

    fetchDocumentStats() {
        this.dashboardService.fetchDashboardDocumentSummary()
        .then((DashboardDocument: any) => {
            this.setState({
                draftCount: DashboardDocument.data["draft_count"],
                awaitingReviewCount: DashboardDocument.data["awaiting_review_count"],
                toReviewCount: DashboardDocument.data["to_review_count"]
            });
        })
        .catch((error) => {
            Util.showToast(new Toast('Error fetching document summaries. ' + error, MessageBarType.error));
        });
    }

    redirectToList(type) {
        this.props.onItemClick(type);
    }

    render() {

        let dashType = this.props.type;
        let iconName = '';
        let canType;
        if (dashType === 'contentcontrol') {
            iconName = 'Rename';
            dashType = 'Content Controls';
            canType = "control";
        }
        else if (dashType === 'template') {
            iconName = 'Articles';
            dashType = 'Templates';
            canType = "template";
        }
        else if (dashType === 'document') {
            iconName = 'FabricFormLibrary';
            dashType = 'Documents';
            canType = "document";
        }


        return (
            <div className='dashboard-Item'>
                <div className="dashboard-Item-Icon">
                    <Icon iconName={iconName}></Icon>                    
                </div>
                <div className="dashboard-Item-Stats pl2">
                        <h1 className="dashboard-Item-Stats-Heading mb1">
                            {dashType}
                        </h1>
                        <div>
                            <div className="dashboard-Item-Stats-Details mb05">
                                <div className="dashboard-Item-Stats-Details-Label">
                                    <Link onClick={() => { this.redirectToList(this.props.type + "-draft"); }}>Drafts</Link>
                                </div>
                                <div className="dashboard-Item-Stats-Details-Value">
                                    {this.state.draftCount === null ? "N/A" : this.state.draftCount}
                                </div>
                            </div>
                            <div className="dashboard-Item-Stats-Details mb05">
                                <div className="dashboard-Item-Stats-Details-Label">                                        
                                    <Link onClick={() => { this.redirectToList(this.props.type + "-awaiting_review"); }}>Awaiting Review</Link>
                                </div>
                                <div className="dashboard-Item-Stats-Details-Value">
                                    {this.state.awaitingReviewCount === null ? "N/A" : this.state.awaitingReviewCount}
                                </div>
                            </div>
                            <Can do="manage" on={canType}>
                                <div className="dashboard-Item-Stats-Details mb05">
                                    <div className="dashboard-Item-Stats-Details-Label">                                        
                                        <Link onClick={() => { this.redirectToList(this.props.type + "-for_review"); }}>For Review</Link>
                                    </div>
                                    <div className="dashboard-Item-Stats-Details-Value">
                                        {this.state.toReviewCount === null ? "N/A" : this.state.toReviewCount}
                                    </div>
                                </div>
                            </Can>
                        </div>                       
                    </div>
            </div>
        );
    }
}
