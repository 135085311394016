import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { MessageBarType, Label, Breadcrumb, IBreadcrumbItem } from 'office-ui-fabric-react';
import { Util, Toast } from "@voxfp/opal_ui_common";
import { SmartTemplateService } from '../../services/smartTemplateService';
import WorkflowButton from '../workflowButtons/workflowButton';

export interface AppProps {
    title?: string;
    config?: object;
    history?: any;
}

export interface AppState {
    id: number;
    title: string;
    description: string,
    playbook: string;
    version: any;
    status: string;
    workflowEvents: Array<any>;
    template: string;
    revisionId: number;
}

export class SmartTemplateDetail extends React.Component<AppProps & RouteComponentProps, AppState>  {

    SmartTemplateService: SmartTemplateService = new SmartTemplateService();

    public breadcrumbList: Array<IBreadcrumbItem> = [];

    constructor(props, state) {
        super(props, state);

        this.breadcrumbList = [
            { text: 'Dashboard', key: 'Dashboard', onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, _item?: IBreadcrumbItem) => window.history.back() },
            { text: "Details", key: 'f1', href: "#" + this.props.location.pathname, isCurrentItem: true }
        ];
    }

    componentDidMount() {
        this.setState(this.reset());
        const query = new URLSearchParams(this.props.match.params);
        const id = Number(query.get('id'));
        this.setState({
            id: id
        });

        this.fetchSmartTemplate(id);
    }

    reset() {
        return ({
            id: null,
            title: "",
            description: "",
            playbook: "",
            version: "",
            status: "",
            workflowEvents: [],
            template: "",
            revisionId: null
        });
    }

    fetchSmartTemplate(id) {
        this.SmartTemplateService.getRevision(id)
            .then((SmartTemplate: any) => {
                this.setState({
                    title: SmartTemplate.label,
                    playbook: SmartTemplate.playbook,
                    description: SmartTemplate.friendlyName,
                    version: SmartTemplate.currentRevision.version,
                    status: SmartTemplate.currentRevision["workflow_state"],
                    workflowEvents: SmartTemplate.currentRevision["workflow_events"],
                    template: SmartTemplate.template.name,
                    revisionId: SmartTemplate.currentRevision.id
                });
            })
            .catch((error) => {
                Util.showToast(new Toast('Error fetching template. ' + error, MessageBarType.error));
            });
    }

    updateRevisionState(id, state) {
        this.SmartTemplateService.updateRevisionState(id, state).then((_data) => {
            Util.showToast(new Toast('Smart Template workflow set to ' + state + '.', MessageBarType.success));
            this.fetchSmartTemplate(this.state.id);
            if (state === "Approve") {
                window.history.back();
            }
        })
        .catch((error) => {
            Util.showToast(new Toast('Error setting workflow. ' + error, MessageBarType.error));
        });
    }

    render() {

        return (
            <div className="opalContent dashboard-Content">
                <h1 className="mt0 mb05">Details {this.state && this.state.title ? "- " + this.state.title : ""}</h1>
                <div className={"breadcrumb"}>
                    <Breadcrumb
                        items={this.breadcrumbList}
                        maxDisplayedItems={3}
                        ariaLabel="Breadcrumb with items rendered as links"
                        overflowAriaLabel="More links"
                    />
                </div>
                {this.state && this.state.id && this.state.playbook !== "" &&
                <div>
                    <div className="ms-Grid-row mt2">
                        <div className="ms-Grid-col ms-sm12 ms-xl5">
                        <div className="ms-Grid-row mb05">
                                <div className="ms-Grid-col ms-sm5">
                                    <Label className="p0">Name</Label>
                                </div>
                                <div className="ms-Grid-col ms-sm7">
                                    {this.state.title}
                                </div>
                            </div>
                            <div className="ms-Grid-row mb05">
                                <div className="ms-Grid-col ms-sm5">
                                    <Label className="p0">Description</Label>
                                </div>
                                <div className="ms-Grid-col ms-sm7">
                                    {this.state.description}
                                </div>
                            </div>
                            <div className="ms-Grid-row mb05">
                                <div className="ms-Grid-col ms-sm5">
                                    <Label className="p0">Playbook</Label>
                                </div>
                                <div className="ms-Grid-col ms-sm7">
                                    {this.state.playbook}
                                </div>
                            </div>
                            <div className="ms-Grid-row mb05">
                                <div className="ms-Grid-col ms-sm5">
                                    <Label className="p0">Version</Label>
                                </div>
                                <div className="ms-Grid-col ms-sm7">
                                    {this.state.version}
                                </div>
                            </div>
                            <div className="ms-Grid-row mb05">
                                <div className="ms-Grid-col ms-sm5">
                                    <Label className="p0">Status</Label>
                                </div>
                                <div className="ms-Grid-col ms-sm7">
                                    {this.state.status}
                                </div>
                            </div>
                            <div className="ms-Grid-row mb2">
                                <div className="ms-Grid-col ms-sm5">
                                    <Label className="p0">Template</Label>
                                </div>
                                <div className="ms-Grid-col ms-sm7">
                                    {this.state.template}
                                </div>
                            </div>
                            
                            {this.state && this.state.workflowEvents.map(
                                (item, i) => {
                                    return (
                                        <WorkflowButton
                                            key={i}
                                            workflowItem={item}
                                            onHandleClick={(id, name) => this.updateRevisionState(id, name)}
                                            id={this.state.revisionId}
                                        />
                                    );
                                })
                            }

                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default SmartTemplateDetail;
