import * as React from 'react';
import {initializeIcons} from '@uifabric/icons';
import { Route, HashRouter, Switch, Redirect } from 'react-router-dom';
import { Login } from './components/login';
import { Dashboard } from './components/dashboard';
import {createTheme, Customizations} from 'office-ui-fabric-react';
import {Header, ToastMessage, OpalStoreService, buildAbilities, AbilityContext} from '@voxfp/opal_ui_common';
import {TemplateDetail} from './components/templates/templates';
import Cookies from 'universal-cookie';
import {SmartTokenDetail} from './components/smartTokens/smartTokens';
import {SmartChartDetail} from './components/smartCharts/smartCharts';
import {SmartSwitchDetail} from './components/smartSwitches/smartSwitches';
import {SmartConditionDetail} from './components/smartConditions/smartConditions';
import {SmartTemplateDetail} from './components/smartTemplates/smartTemplates';
import {SmartRepeatDetail} from './components/smartRepeats/smartRepeats';
import DocumentDetail from './components/documents/documents';

const myTheme = createTheme({
    palette: {
        themePrimary: '#45b6b7',
        themeLighterAlt: '#f6fcfc',
        themeLighter: '#dbf3f4',
        themeLight: '#bee9ea',
        themeTertiary: '#85d3d4',
        themeSecondary: '#57bec0',
        themeDarkAlt: '#3fa4a5',
        themeDark: '#358a8c',
        themeDarker: '#276667',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#c0c9c9',
        neutralSecondary: '#859292',
        neutralPrimaryAlt: '#536060',
        neutralPrimary: '#404b4b',
        neutralDark: '#303838',
        black: '#232929',
        white: '#ffffff',
    }
});

initializeIcons();

export interface AppProps {
    history?: any;
}

export interface AppState {
    headerVisible: boolean;
}

const ProtectedRoute = ({ component: Comp, loggedIn, path, history, ...rest }) => {
    return (
            <Route
                path={path}
                {...rest}
                render={(props) => {
                    return loggedIn ? (
                        <Comp history={history} {...props} />
                    ) : (
                            <Redirect
                                to={'/login'}
                            />
                        );
                }}
            />
    );
};

const cookies = new Cookies();

export class App extends React.Component<AppProps, AppState> {
    
    private authenticated = false;

    constructor(props: any, context: any) {
        super(props, context);
        this.state = this.initializeState();
    }

    initializeState() {
        return {
            headerVisible: true
        };
    }

    onRouteChanged = () => {
        let authToken = OpalStoreService.getProperty('authToken');
        if (authToken) {
            this.authenticated = true;
        }
        else {
            this.authenticated = false;
        }
        return true;
    }

    hideHeader(e) {
        this.setState({
            headerVisible: e
        });
    }

    render() {
        
        Customizations.applySettings({ theme: myTheme });
        
        const rules = OpalStoreService.getProperty("permissions");
        const ability = buildAbilities(rules);
        const user = OpalStoreService.getProperty("user");

        return (
            this.onRouteChanged() &&    
            <AbilityContext.Provider value={ability}>
                <HashRouter>
                    {this.state.headerVisible && this.authenticated &&
                        <Header history={this.props.history} user={user} />
                    }
                    
                    <div className="opalPage">
                        <div className="toastPosition">
                            <ToastMessage />
                        </div>
                        
                        <Switch>
                            <Route path='/login' render={props => this.onRouteChanged() &&  <Login onLoginLoad={(e) => { this.hideHeader(e); }} {...props} />}/>
                            <ProtectedRoute path="/dashboard" loggedIn={this.authenticated} component={Dashboard} history={this.props.history} />
                            <ProtectedRoute path="/template/:id" loggedIn={this.authenticated} component={TemplateDetail} history={this.props.history} />
                            <ProtectedRoute path="/document/:id" loggedIn={this.authenticated} component={DocumentDetail} history={this.props.history} />
                            <ProtectedRoute path="/smart-tokens/:id" loggedIn={this.authenticated} component={SmartTokenDetail} history={this.props.history} />
                            <ProtectedRoute path="/smart-charts/:id" loggedIn={this.authenticated} component={SmartChartDetail} history={this.props.history} />
                            <ProtectedRoute path="/smart-switches/:id" loggedIn={this.authenticated} component={SmartSwitchDetail} history={this.props.history} />
                            <ProtectedRoute path="/smart-conditions/:id" loggedIn={this.authenticated} component={SmartConditionDetail} history={this.props.history} />
                            <ProtectedRoute path="/smart-templates/:id" loggedIn={this.authenticated} component={SmartTemplateDetail} history={this.props.history} />
                            <ProtectedRoute path="/smart-repeats/:id" loggedIn={this.authenticated} component={SmartRepeatDetail} history={this.props.history} />
                            <Redirect to="/dashboard" />
                        </Switch>
                        
                    </div>
                </HashRouter>
            </AbilityContext.Provider>   

        );
    }
}

export default App;
