import {Log, Util} from "@voxfp/opal_ui_common";
import {SmartCondition} from "../models/smartCondition";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";

export class SmartConditionService {

    getRevision(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.SmartCondition, id, null, true).then((smartCondition: SmartCondition) => {
                resolve(smartCondition);
            }).catch(error => {   
                Log.error("SmartConditionService:getSmartCondition", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    deleteRevision(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.removeEntity(OpalEntity.SmartCondition, id)
            .then((feedback) => {
                resolve(feedback);
            }).catch(error => {            
                Log.error("SmartConditionService:deleteRevision with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    updateRevisionState(id, status) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.WorkflowEvent, {"the_action": status}, id)
            .then((feedback) => {
                resolve(feedback);
            }).catch(error => {            
                Log.error("SmartConditionRevisionsService:updateRevisionState with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

}
