import {Log, Util} from "@voxfp/opal_ui_common";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from '../utils/commonUtils';

export class DashboardService {

    fetchDashboardTemplateSummary() {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.DashboardTemplateSummary, null, null, true)
            .then((DashboardTemplates: any) => {
                resolve(DashboardTemplates);
            }).catch((error) => {
                Log.error("DashboardService:fetchDashboardTemplateSummary", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchDashboardContentControlSummary() {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.DashboardContentControlSummary, null, null, true)
            .then((DashboardContentControls: any) => {
                resolve(DashboardContentControls);
            }).catch((error) => {
                Log.error("DashboardService:fetchDashboardContentControlSummary", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchDashboardDocumentSummary() {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.DashboardDocumentSummary, null, null, true)
            .then((DashboardDocuments: any) => {
                resolve(DashboardDocuments);
            }).catch((error) => {
                Log.error("DashboardService:fetchDashboardDocumentSummary", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchTemplateRevisionsByType(type) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.TemplateRevisionsByType, type, null, true)
            .then((Templates: any) => {
                resolve(Templates);
            }).catch((error) => {
                Log.error("DashboardService:fetchTemplateRevisionsByType", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchContentControlRevisionsByType(type) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.ContentControlRevisionsByType, type, null, true)
            .then((ContentControls: any) => {
                resolve(ContentControls);
            }).catch((error) => {
                Log.error("DashboardService:fetchContentControlRevisionsByType", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchDocumentsByType(type) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.DocumentsByType, type, null, true)
            .then((documents: any) => {
                resolve(documents);
            }).catch((error) => {
                Log.error("DashboardService:fetchDocumentsByType", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

}
