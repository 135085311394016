import {Model} from "@voxfp/opal_ui_common";
import { CurrentRevision } from "./currentRevision";

export class SmartRepeat extends Model<SmartRepeat> {

    id?: number;
    smartRepeatType?: string;
    smartRepeatTypeId?: number;
    label?: string;
    friendlyName?: string;
    updatedAt?: any;
    playbook?: string;
    playbookId?: number;
    template?: any;
    templateRevision?: any;
    currentRevision?: CurrentRevision;

    constructor(id?: number, smartRepeatType?: string, smartRepeatTypeId?: number, label?: string, friendlyName?: string, updatedAt?: any, playbook?: string, playbookId?: number, template?: any, templateRevision?: any, currentRevision?: CurrentRevision) {
        super(SmartRepeat);
        this.id = id;
        this.smartRepeatType = smartRepeatType;
        this.smartRepeatTypeId = smartRepeatTypeId;
        this.label = label;
        this.friendlyName = friendlyName;
        this.updatedAt = updatedAt;
        this.playbook = playbook;
        this.playbookId = playbookId;
        this.template = template;
        this.templateRevision = templateRevision; 
        this.currentRevision = currentRevision;   
    }
}
