import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { MessageBarType, Label, Breadcrumb, IBreadcrumbItem, TextField, Dropdown } from 'office-ui-fabric-react';
import { Util, Toast, Log, SwitchBuilder, SwitchValue } from "@voxfp/opal_ui_common";
import { ContentControlService } from '../../services/contentControlService';
import { SmartSwitchService } from '../../services/smartSwitchService';
import WorkflowButton from '../workflowButtons/workflowButton';
export interface AppProps {
    title?: string;
    config?: object;
    history?: any;
}

export interface AppState {
    id: number;
    title: string;
    description: string;
    playbook: string;
    version: any;
    status: string;
    workflowEvents: Array<any>;
    contentControls: Array<any>;
    switch: Array<SwitchValue>;
    templates: Array<any>;
    defaultContentControlRevisionId: any;
    caseContentControlRevisionId: number;
    revisionId: number;
}

export class SmartSwitchDetail extends React.Component<AppProps & RouteComponentProps, AppState>  {

    contentControlService: ContentControlService = new ContentControlService();
    SmartSwitchService: SmartSwitchService = new SmartSwitchService();

    public breadcrumbList: Array<IBreadcrumbItem> = [];

    constructor(props, state) {
        super(props, state);

        this.breadcrumbList = [
            { text: 'Dashboard', key: 'Dashboard', onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, _item?: IBreadcrumbItem) => window.history.back() },
            { text: "Details", key: 'f1', href: "#" + this.props.location.pathname, isCurrentItem: true }
        ];
    }

    componentDidMount() {
        this.setState(this.reset());
        const query = new URLSearchParams(this.props.match.params);
        const id = Number(query.get('id'));
        this.setState({
            id: id
        });

        this.fetchSmartSwitch(id);
    }

    reset() {
        return ({
            id: null,
            title: "",
            description: "",
            playbook: "",
            version: "",
            status: "",
            workflowEvents: [],
            contentControls: [],
            switch: [],
            templates: [],
            defaultContentControlRevisionId: null,
            caseContentControlRevisionId: null,
            revisionId: null
        });
    }

    fetchSmartSwitch(id) {
        this.SmartSwitchService.getRevision(id)
            .then((SmartSwitch: any) => {
                this.setState({
                    title: SmartSwitch.label,
                    description: SmartSwitch.friendlyName,
                    playbook: SmartSwitch.playbook,
                    version: SmartSwitch.currentRevision.version,
                    status: SmartSwitch.currentRevision["workflow_state"],
                    workflowEvents: SmartSwitch.currentRevision["workflow_events"],
                    revisionId: SmartSwitch.currentRevision.id,
                    switch: SmartSwitch.switch,
                    caseContentControlRevisionId: SmartSwitch.caseContentControlRevisionId.toString(),
                    defaultContentControlRevisionId: SmartSwitch.defaultContentControlRevisionId ? SmartSwitch.defaultContentControlRevisionId.toString() : 0
                });
                this.fetchContentControlRevisions(SmartSwitch.playbookId);
                this.fetchSmartTemplateRevisions(SmartSwitch.playbookId);
            })
            .catch((error) => {
                Util.showToast(new Toast('Error fetching switch. ' + error, MessageBarType.error));
            });
    }

    fetchContentControlRevisions(playbookId) {
        this.contentControlService.fetchContentControlRevisionsByTypeFromPlaybook(playbookId, "SmartToken").then((data: any) => {
            this.setState({
                contentControls: data
            });
        }).catch(error => {
            Log.error('Error fetching Tokens from PlaybookId=' + playbookId, error);
            this.setState({
                contentControls: []
            });
            Util.showToast(new Toast('Error fetching Tokens from Playbook.', MessageBarType.error));
        });
    }

    fetchSmartTemplateRevisions(playbookId) {
        this.contentControlService.fetchContentControlRevisionsByTypeFromPlaybook(playbookId, "SmartTemplate").then((data: any) => {
            this.setState({
                templates: data
            });
        }).catch(error => {
            Log.error('Error fetching Sub-Templates from PlaybookId=' + playbookId, error);
            this.setState({
                contentControls: []
            });
            Util.showToast(new Toast('Error fetching Sub-Templates from Playbook.', MessageBarType.error));
        });
    }

    updateRevisionState(id, state) {
        this.SmartSwitchService.updateRevisionState(id, state).then((_data) => {
            Util.showToast(new Toast('Smart Switch workflow set to ' + state + '.', MessageBarType.success));
            this.fetchSmartSwitch(this.state.id);
            if (state === "Approve") {
                window.history.back();
            }
        })
            .catch((error) => {
                Util.showToast(new Toast('Error setting workflow. ' + error, MessageBarType.error));
            });
    }

    render() {

        return (
            <div className="opalContent dashboard-Content">
                <h1 className="mt0 mb05">Details {this.state && this.state.title ? "- " + this.state.title : ""}</h1>
                <div className={"breadcrumb"}>
                    <Breadcrumb
                        items={this.breadcrumbList}
                        maxDisplayedItems={3}
                        ariaLabel="Breadcrumb with items rendered as links"
                        overflowAriaLabel="More links"
                    />
                </div>
                {this.state && this.state.id && this.state.playbook !== "" &&
                    <div>
                        <div className="ms-Grid-row mt2">
                            <div className="ms-Grid-col ms-sm12 ms-xl5">
                                <div className="ms-Grid-row mb05">
                                    <div className="ms-Grid-col ms-sm5">
                                        <Label className="p0">Name</Label>
                                    </div>
                                    <div className="ms-Grid-col ms-sm7">
                                        {this.state.title}
                                    </div>
                                </div>
                                <div className="ms-Grid-row mb05">
                                    <div className="ms-Grid-col ms-sm5">
                                        <Label className="p0">Description</Label>
                                    </div>
                                    <div className="ms-Grid-col ms-sm7">
                                        {this.state.description}
                                    </div>
                                </div>
                                <div className="ms-Grid-row mb05">
                                    <div className="ms-Grid-col ms-sm5">
                                        <Label className="p0">Playbook</Label>
                                    </div>
                                    <div className="ms-Grid-col ms-sm7">
                                        {this.state.playbook}
                                    </div>
                                </div>
                                <div className="ms-Grid-row mb05">
                                    <div className="ms-Grid-col ms-sm5">
                                        <Label className="p0">Version</Label>
                                    </div>
                                    <div className="ms-Grid-col ms-sm7">
                                        {this.state.version}
                                    </div>
                                </div>
                                <div className="ms-Grid-row mb05">
                                    <div className="ms-Grid-col ms-sm5">
                                        <Label className="p0">Status</Label>
                                    </div>
                                    <div className="ms-Grid-col ms-sm7">
                                        {this.state.status}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-xl2">
                                <Label className="p0">Switch</Label>
                            </div>
                                    
                            <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4">
                                <Dropdown responsiveMode={2}
                                    disabled={true}
                                    className={"readOnlyStyle"}
                                    placeholder=''
                                    notifyOnReselect={true}
                                    id='contentControlSwitch'
                                    ariaLabel='Content Control to use as Switch'
                                    defaultSelectedKey={this.state.caseContentControlRevisionId}
                                    options={this.state ? this.state.contentControls : []}
                                />

                            </div>
                        </div>
                        <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-xl2">
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-xl10">
                                <div className="switch">

                                    <div className="switchItem">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md8">
                                                <TextField
                                                    readOnly
                                                    value="Default"
                                                    className="readOnlyStyle"
                                                >
                                                </TextField>
                                            </div>
                                            <div className="ms-Grid-col ms-sm12 ms-md4">
                                                <Dropdown responsiveMode={2}
                                                    disabled={true}
                                                    className={"readOnlyStyle"}
                                                    placeholder=''
                                                    notifyOnReselect={true}
                                                    id='templateTrue'
                                                    ariaLabel='Template to use if switch statement is true'
                                                    defaultSelectedKey={this.state.defaultContentControlRevisionId}
                                                    options={this.state ? this.state.templates : []}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {this.state && this.state.templates.length > 0 &&
                                        <SwitchBuilder
                                            templates={this.state ? this.state.templates : []}
                                            rules={this.state && this.state.switch}
                                            readOnly={true}
                                        />
                                    }
                                </div>
                            </div>
                        </div>

                        
                        <div className="ms-Grid-row mt2">

                            <div className="ms-Grid-col ms-sm12 ms-xl5">
                                
                                {this.state && this.state.workflowEvents.map(
                                    (item, i) => {
                                        return (
                                            <WorkflowButton
                                                key={i}
                                                workflowItem={item}
                                                onHandleClick={(id, name) => this.updateRevisionState(id, name)}
                                                id={this.state.revisionId}
                                            />
                                        );
                                    })
                                }

                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default SmartSwitchDetail;
