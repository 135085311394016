import {RouteComponentProps} from "react-router";
import * as React from "react";
import {Breadcrumb, IBreadcrumbItem, Label, MessageBarType} from "office-ui-fabric-react";
import { Util, Toast } from "@voxfp/opal_ui_common";
import { SmartChartService } from '../../services/smartChartService';
import WorkflowButton from "../workflowButtons/workflowButton";

export interface AppProps {
    title?: string;
    config?: object;
    history?: any;
}

export interface AppState {
    id: number;
    label: string;
    friendlyName: string;
    playbookId: number;
    playbookName: string;
    version: any;
    status: string;
    workflowEvents: Array<any>;
    smartChartTypeName: string;
    smartChartTypeId: number;
    revisionId: number;
}

export class SmartChartDetail extends React.Component<AppProps & RouteComponentProps, AppState> {
    SmartChartService: SmartChartService = new SmartChartService();

    public breadcrumbList: Array<IBreadcrumbItem> = [];

    constructor(props, state) {
        super(props, state);

        this.breadcrumbList = [
            { text: 'Dashboard', key: 'Dashboard', onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, _item?: IBreadcrumbItem) => window.history.back() },
            { text: "Details", key: 'f1', href: "#" + this.props.location.pathname, isCurrentItem: true }
        ]
    }

    componentDidMount() {
        this.setState(this.reset());
        const query = new URLSearchParams(this.props.match.params);
        const id = Number(query.get('id'));
        this.setState({
            id: id
        });
        this.fetchSmartChart(id);
    }

    reset() {
        return ({
            id: null,
            label: "",
            friendlyName: "",
            playbookId: 0,
            playbookName: '',
            version: "",
            status: "",
            workflowEvents: [],
            smartChartTypeName: '',
            smartChartTypeId: 0,
            revisionId: null
        });
    }

    fetchSmartChart(id) {
        this.SmartChartService.getRevision(id)
            .then((SmartChart: any) => {
                this.setState({
                    label: SmartChart.label,
                    friendlyName: SmartChart.friendlyName,
                    playbookId: SmartChart.playbook.id,
                    playbookName: SmartChart.playbook.name,
                    smartChartTypeName: SmartChart.smartChartType.name,
                    smartChartTypeId: SmartChart.smartChartType.id,
                    version: SmartChart.revision.version,
                    status: SmartChart.revision.workflow_state,
                    workflowEvents: SmartChart.revision.workflow_events,
                    revisionId: SmartChart.revision.id
                });
            })
            .catch((error) => {
                Util.showToast(new Toast('Error fetching token. ' + error, MessageBarType.error));
            });
    }

    updateRevisionState(id, state) {
        this.SmartChartService.updateRevisionState(id, state).then((_data) => {
            Util.showToast(new Toast('Smart Chart workflow set to ' + state + '.', MessageBarType.success));
            this.fetchSmartChart(this.state.id);
            if (state === "Approve") {
                window.history.back();
            }
        })
            .catch((error) => {
                Util.showToast(new Toast('Error setting workflow. ' + error, MessageBarType.error));
            });
    }

    render() {
        return (
            <div className="opalContent dashboard-Content">
                <h1 className="mt0 mb05">Details {this.state && this.state.label ? "- " + this.state.label : ""}</h1>
                <div className={"breadcrumb"}>
                    <Breadcrumb
                        items={this.breadcrumbList}
                        maxDisplayedItems={3}
                        ariaLabel="Breadcrumb with items rendered as links"
                        overflowAriaLabel="More links"
                    />
                </div>
                {this.state && this.state.id && this.state.playbookName !== "" &&
                <div>
                  <div className="ms-Grid-row mt2">
                    <div className="ms-Grid-col ms-sm12 ms-xl5">
                      <div className="ms-Grid-row mb05">
                        <div className="ms-Grid-col ms-sm5">
                          <Label className="p0">Name</Label>
                        </div>
                        <div className="ms-Grid-col ms-sm7">
                            {this.state.label}
                        </div>
                      </div>
                      <div className="ms-Grid-row mb05">
                        <div className="ms-Grid-col ms-sm5">
                          <Label className="p0">Description</Label>
                        </div>
                        <div className="ms-Grid-col ms-sm7">
                            {this.state.friendlyName}
                        </div>
                      </div>
                      <div className="ms-Grid-row mb05">
                        <div className="ms-Grid-col ms-sm5">
                          <Label className="p0">Playbook</Label>
                        </div>
                        <div className="ms-Grid-col ms-sm7">
                            {this.state.playbookName}
                        </div>
                      </div>
                      <div className="ms-Grid-row mb05">
                        <div className="ms-Grid-col ms-sm5">
                          <Label className="p0">Smart Chart Type</Label>
                        </div>
                        <div className="ms-Grid-col ms-sm7">
                            {this.state.smartChartTypeName}
                        </div>
                      </div>
                      <div className="ms-Grid-row mb05">
                        <div className="ms-Grid-col ms-sm5">
                          <Label className="p0">Version</Label>
                        </div>
                        <div className="ms-Grid-col ms-sm7">
                            {this.state.version}
                        </div>
                      </div>
                      <div className="ms-Grid-row mb2">
                        <div className="ms-Grid-col ms-sm5">
                          <Label className="p0">Status</Label>
                        </div>
                        <div className="ms-Grid-col ms-sm7">
                            {this.state.status}
                        </div>
                      </div>

                        {this.state && this.state.workflowEvents.map(
                            (item, i) => {
                                return (
                                    <WorkflowButton
                                        key={i}
                                        workflowItem={item}
                                        onHandleClick={(id, name) => this.updateRevisionState(id, name)}
                                        id={this.state.revisionId}
                                    />
                                );
                            })
                        }

                    </div>
                  </div>
                </div>
                }
            </div>
        );
    }
}

export default SmartChartDetail;
