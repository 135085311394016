import {Log, Util} from "@voxfp/opal_ui_common";
import {SmartTemplate} from "../models/smartTemplate";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";

export class SmartTemplateService {

    getRevision(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.SmartTemplate, id, null, true).then((smartTemplate: SmartTemplate) => {
                resolve(smartTemplate);
            }).catch(error => {   
                Log.error("SmartTemplateService:getSmartTemplate", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    deleteRevision(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.removeEntity(OpalEntity.SmartTemplate, id)
            .then((feedback) => {
                resolve(feedback);
            }).catch(error => {            
                Log.error("SmartTemplateService:deleteRevision with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    updateRevisionState(id, status) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.WorkflowEvent, {"the_action": status}, id)
            .then((feedback) => {
                resolve(feedback);
            }).catch(error => {            
                Log.error("SmartTemplateRevisionsService:updateRevisionState with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

}
