import * as React from 'react';
import { DefaultButton } from 'office-ui-fabric-react';
import Can from "@voxfp/opal_ui_common/dist/components/permissions/can";

export interface AppProps {
    key: number;
    workflowItem: any;
    onHandleClick: any;
    id: number;
}

export class WorkflowButton extends React.Component<AppProps, any> {

    render() {
        let workflowIcon;
        if (this.props.workflowItem.name === "SubmitForReview") {
            workflowIcon = "ComplianceAudit";
        }
        else if (this.props.workflowItem.name === "Approve") {
            workflowIcon = "Accept";
        }
        else if (this.props.workflowItem.name === "Reject") {
            workflowIcon = "Blocked";
        }
        else if (this.props.workflowItem.name === "Archive") {
            workflowIcon = "Archive";
        }
        return (               
            <Can do={(this.props.workflowItem.name === "Approve" ||  "Reject") ? "approve" : "read"} on="control">
                <DefaultButton
                    primary={(this.props.workflowItem.name === "Reject" || this.props.workflowItem.name === "Archive") ? false : true}
                    className="mb1"
                    key={this.props.key}
                    style={{ width: "100%" }}
                    text={this.props.workflowItem.description}
                    iconProps={{ iconName: workflowIcon }}
                    onClick={() => {
                        this.props.onHandleClick(this.props.id, this.props.workflowItem.name);
                    }}
                />
            </Can>
        );
    }

}

export default WorkflowButton;
