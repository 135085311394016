import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { MessageBarType, Label, Breadcrumb, IBreadcrumbItem, Dropdown } from 'office-ui-fabric-react';
import { Util, Toast, Log, ConditionBuilder } from "@voxfp/opal_ui_common";
import { ContentControlService } from '../../services/contentControlService';
import { SmartConditionService } from '../../services/smartConditionService';
import WorkflowButton from '../workflowButtons/workflowButton';

export interface AppProps {
    title?: string;
    config?: object;
    history?: any;
}

export interface AppState {
    id: number;
    title: string;
    description: string;
    playbook: string;
    version: any;
    status: string;
    workflowEvents: Array<any>;
    contentControls: Array<any>;
    expression: any;
    templates: Array<any>;
    contentControlRevisionIdIfTrue: number;
    contentControlRevisionIdIfFalse: number;
    revisionId: number;
}

export class SmartConditionDetail extends React.Component<AppProps & RouteComponentProps, AppState>  {

    contentControlService: ContentControlService = new ContentControlService();
    SmartConditionService: SmartConditionService = new SmartConditionService();

    public breadcrumbList: Array<IBreadcrumbItem> = [];

    constructor(props, state) {
        super(props, state);

        this.breadcrumbList = [
            { text: 'Dashboard', key: 'Dashboard', onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, _item?: IBreadcrumbItem) => window.history.back() },
            { text: "Details", key: 'f1', href: "#" + this.props.location.pathname, isCurrentItem: true }
        ];
    }

    componentDidMount() {
        this.setState(this.reset());
        const query = new URLSearchParams(this.props.match.params);
        const id = Number(query.get('id'));
        this.setState({
            id: id
        });

        this.fetchSmartCondition(id);
    }

    reset() {
        return ({
            id: null,
            title: "",
            description: "",
            playbook: "",
            version: "",
            status: "",
            workflowEvents: [],
            contentControls: [],
            expression: [],
            templates: [],
            contentControlRevisionIdIfTrue: null,
            contentControlRevisionIdIfFalse: null,
            revisionId: null
        });
    }

    fetchSmartCondition(id) {
        this.SmartConditionService.getRevision(id)
            .then((SmartCondition: any) => {
                this.setState({
                    title: SmartCondition.label,
                    description: SmartCondition.friendlyName,
                    playbook: SmartCondition.playbook,
                    version: SmartCondition.currentRevision.version,
                    status: SmartCondition.currentRevision["workflow_state"],
                    workflowEvents: SmartCondition.currentRevision["workflow_events"],
                    expression: SmartCondition.expression,
                    contentControlRevisionIdIfFalse: SmartCondition.contentControlRevisionIdIfFalse ? SmartCondition.contentControlRevisionIdIfFalse.toString() : 0,
                    contentControlRevisionIdIfTrue: SmartCondition.contentControlRevisionIdIfTrue ? SmartCondition.contentControlRevisionIdIfTrue.toString() : 0,
                    revisionId: SmartCondition.currentRevision.id
                });
                this.fetchContentControlRevisions(SmartCondition.playbookId);
                this.fetchSmartTemplateRevisions(SmartCondition.playbookId);
            })
            .catch((error) => {
                Util.showToast(new Toast('Error fetching condition. ' + error, MessageBarType.error));
            });
    }

    fetchContentControlRevisions(playbookId) {
        this.contentControlService.fetchContentControlRevisionsByTypeFromPlaybook(playbookId, "SmartToken").then((data: any) => {
            this.setState({
                contentControls: data
            });
        }).catch(error => {
            Log.error('Error fetching Tokens from PlaybookId=' + playbookId, error);
            this.setState({
                contentControls: []
            });
            Util.showToast(new Toast('Error fetching Tokens from Playbook.', MessageBarType.error));
        });
    }

    fetchSmartTemplateRevisions(playbookId) {
        this.contentControlService.fetchContentControlRevisionsByTypeFromPlaybook(playbookId, "SmartTemplate").then((data: any) => {
            this.setState({
                templates: data
            });
        }).catch(error => {
            Log.error('Error fetching Sub-Templates from PlaybookId=' + playbookId, error);
            this.setState({
                contentControls: []
            });
            Util.showToast(new Toast('Error fetching Sub-Templates from Playbook.', MessageBarType.error));
        });
    }

    updateRevisionState(id, state) {
        this.SmartConditionService.updateRevisionState(id, state).then((_data) => {
            Util.showToast(new Toast('Smart Condition workflow set to ' + state + '.', MessageBarType.success));
            this.fetchSmartCondition(this.state.id);
            if (state === "Approve") {
                window.history.back();
            }
        })
            .catch((error) => {
                Util.showToast(new Toast('Error setting workflow. ' + error, MessageBarType.error));
            });
    }

    render() {

        return (
            <div className="opalContent dashboard-Content">
                <h1 className="mt0 mb05">Details {this.state && this.state.title ? "- " + this.state.title : ""}</h1>
                <div className={"breadcrumb"}>
                    <Breadcrumb
                        items={this.breadcrumbList}
                        maxDisplayedItems={3}
                        ariaLabel="Breadcrumb with items rendered as links"
                        overflowAriaLabel="More links"
                    />
                </div>
                {this.state && this.state.id && this.state.playbook !== "" &&
                    <div>
                        
                        <div className="ms-Grid-row mt2">
                            <div className="ms-Grid-col ms-sm12 ms-xl5">
                                <div className="ms-Grid-row mb05">
                                    <div className="ms-Grid-col ms-sm5">
                                        <Label className="p0">Name</Label>
                                    </div>
                                    <div className="ms-Grid-col ms-sm7">
                                        {this.state.title}
                                    </div>
                                </div>
                                <div className="ms-Grid-row mb05">
                                    <div className="ms-Grid-col ms-sm5">
                                        <Label className="p0">Description</Label>
                                    </div>
                                    <div className="ms-Grid-col ms-sm7">
                                        {this.state.description}
                                    </div>
                                </div>
                                <div className="ms-Grid-row mb05">
                                    <div className="ms-Grid-col ms-sm5">
                                        <Label className="p0">Playbook</Label>
                                    </div>
                                    <div className="ms-Grid-col ms-sm7">
                                        {this.state.playbook}
                                    </div>
                                </div>
                                <div className="ms-Grid-row mb05">
                                    <div className="ms-Grid-col ms-sm5">
                                        <Label className="p0">Version</Label>
                                    </div>
                                    <div className="ms-Grid-col ms-sm7">
                                        {this.state.version}
                                    </div>
                                </div>
                                <div className="ms-Grid-row mb05">
                                    <div className="ms-Grid-col ms-sm5">
                                        <Label className="p0">Status</Label>
                                    </div>
                                    <div className="ms-Grid-col ms-sm7">
                                        {this.state.status}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="ms-Grid-row mb05">
                            <div className="ms-Grid-col ms-sm12 ms-xl2">
                                <Label className="p0">Condition</Label>
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-xl10">
                                {this.state && this.state.contentControls.length > 0 && this.state.expression !== null &&
                                    <ConditionBuilder
                                        conditions={this.state.contentControls}
                                        rules={this.state.expression}
                                        readOnly={true}
                                    />
                                }
                            </div>
                        </div>


                        <div className="ms-Grid-row mt2">
                            <div className="ms-Grid-col ms-sm12 ms-xl5">
                                <div className="ms-Grid-row mb05">
                                    <div className="ms-Grid-col ms-sm5">
                                        <Label className="p0">Sub-Template if true</Label>
                                    </div>
                                    <div className="ms-Grid-col ms-sm7">
                                        <Dropdown responsiveMode={2}
                                            disabled={true}
                                            className={"readOnlyStyle mb05"}
                                            placeholder=''
                                            notifyOnReselect={true}
                                            id='templateTrue'
                                            ariaLabel='Template to use if smartCondition statement is true'
                                            defaultSelectedKey={this.state.contentControlRevisionIdIfTrue || 0}
                                            options={ this.state ? this.state.templates : [] }
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row mb05">
                                    <div className="ms-Grid-col ms-sm5">
                                        <Label className="p0">Sub-Template if false (Optional)</Label>
                                    </div>
                                    <div className="ms-Grid-col ms-sm7">
                                        <Dropdown responsiveMode={2}
                                            disabled={true}
                                            className={"readOnlyStyle"}
                                            placeholder=''
                                            notifyOnReselect={true}
                                            id='templateFalse'
                                            ariaLabel='Template to use if smartCondition statement is false'
                                            defaultSelectedKey={this.state.contentControlRevisionIdIfFalse}
                                            options={ this.state ? this.state.templates : [] }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="ms-Grid-row mt2">

                            <div className="ms-Grid-col ms-sm12 ms-xl5">
                                
                            {this.state && this.state.workflowEvents.map(
                                    (item, i) => {
                                        return (
                                            <WorkflowButton
                                                key={i}
                                                workflowItem={item}
                                                onHandleClick={(id, name) => this.updateRevisionState(id, name)}
                                                id={this.state.revisionId}
                                            />
                                        );
                                    })
                                }

                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default SmartConditionDetail;
