import * as React from 'react';
import { DashboardItem } from './dashboardItem';
import { MessageBarType } from 'office-ui-fabric-react';
import { Util, Toast, OpalTable } from "@voxfp/opal_ui_common";
import { DashboardService } from '../services/dashboardService';
import Can from "@voxfp/opal_ui_common/dist/components/permissions/can";

export interface AppProps {
    history: any;
    location?: any;
}

export interface AppState {
    listData: any;
    listColumns: any;
    listTitle: string;
}

const templateListColumns = [
    {
        field: "template.name",
        headerText: "Name",
        width: "35",
        itemType: "Link",
        customAttributes: {
            urlTemplate: "#/template/{id}"
        }
    },
    {
        field: "author",
        headerText: "Author",
        width: "20",
        filter: "Checkbox"
    },
    {
        field: "version",
        headerText: "Version",
        width: "15",
        format: "n"
    },
    {
        field: "workflowState",
        headerText: "Status",
        width: "15",
        filter: "Checkbox",
        disableSorting: true,
        disableFiltering: true
    },
    {
        field: "updatedAt",
        headerText: "Last Edit",
        width: "15",
        type: "date",
        format: "dd/MM/yyyy"
    }
];

const contentControlListColumns = [
    {
        field: "label",
        headerText: "Name",
        width: "35",
        itemType: "Link",
        customAttributes: {
            urlTemplate: "#/{details.type}/{details.id}"
        }
    },
    {
        field: "details.type",
        headerText: "Type",
        width: "20",
        filter: "Checkbox"
    },
    {
        field: "author",
        headerText: "Author",
        width: "20",
        filter: "Checkbox"
    },
    {
        field: "version",
        headerText: "Version",
        width: "15",
        format: "n"
    },
    {
        field: "workflowState",
        headerText: "Status",
        width: "15",
        filter: "Checkbox",
        disableSorting: true,
        disableFiltering: true
    },
    {
        field: "updatedAt",
        headerText: "Last Edit",
        width: "15",
        type: "date",
        format: "dd/MM/yyyy"
    }
];

const documentListColumns = [
    {
        field: "name",
        headerText: "Name",
        width: "35",
        itemType: "Link",
        customAttributes: {
            urlTemplate: "#/document/{id}"
        }
    },
    {
        field: "author",
        headerText: "Author",
        width: "15",
        filter: "Checkbox"
    },
    {
        field: "templateName",
        headerText: "Template",
        width: "15"
    },
    {
        field: "templateFamilyName",
        headerText: "Template Family",
        width: "20",
        filter: "Checkbox"
    },
    {
        field: "updatedAt",
        headerText: "Last Edit",
        width: "15",
        type: "date",
        format: "dd/MM/yyyy"
    }
];

export class Dashboard extends React.Component<AppProps, AppState> {

    dashboardService: DashboardService = new DashboardService();

    componentDidMount() {
        this.setState(
            this.reset()
        );
        this.fetchListItems();
    }

    reset() {
        return ({
            listData: null,
            listColumns: null,
            listTitle: ''
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location) {
            this.fetchListItems();
        }
    }

    redirectDashboard(type) {
        this.props.history.push("/dashboard/" + type);
        this.fetchListItems();
    }

    fetchListItems() {
        let type = this.props.history.location.pathname.split('/dashboard/')[1];
        this.setState({
            listTitle: type
        });
        if (type === "template-draft" || type === "template-awaiting_review" || type === "template-for_review") {
            type = type.split('-')[1];
            this.dashboardService.fetchTemplateRevisionsByType(type)
                .then((Templates: any) => {
                    this.setState({
                        listData: Templates,
                        listColumns: templateListColumns
                    });
                })
                .catch((error) => {
                    Util.showToast(new Toast('Error fetching items. ' + error, MessageBarType.error));
                });
        }
        else if (type === "contentcontrol-draft" || type === "contentcontrol-awaiting_review" || type === "contentcontrol-for_review") {
            type = type.split('-')[1];
            this.dashboardService.fetchContentControlRevisionsByType(type)
                .then((ContentControls: any) => {
                    this.setState({
                        listData: ContentControls,
                        listColumns: contentControlListColumns
                    });
                })
                .catch((error) => {
                    Util.showToast(new Toast('Error fetching items. ' + error, MessageBarType.error));
                });
        }
        else if (type === "document-draft" || type === "document-awaiting_review" || type === "document-for_review") {
            type = type.split('-')[1];
            this.dashboardService.fetchDocumentsByType(type)
                .then((Documents: any) => {
                    this.setState({
                        listData: Documents,
                        listColumns: documentListColumns
                    });
                })
                .catch((error) => {
                    Util.showToast(new Toast('Error fetching items. ' + error, MessageBarType.error));
                });
        }
        else {
            this.setState(
                this.reset()
            );
        }
    }

    render() {

        let listTitle;

        if (this.state && this.state.listTitle === "template-draft") {
            listTitle = "Template Drafts";
        }
        else if (this.state && this.state.listTitle === "template-awaiting_review") {
            listTitle = "Templates Awaiting Review";
        }
        else if (this.state && this.state.listTitle === "template-for_review") {
            listTitle = "Templates For Review";
        }
        else if (this.state && this.state.listTitle === "contentcontrol-draft") {
            listTitle = "Content Control Drafts";
        }
        else if (this.state && this.state.listTitle === "contentcontrol-awaiting_review") {
            listTitle = "Content Controls Awaiting Review";
        }
        else if (this.state && this.state.listTitle === "contentcontrol-for_review") {
            listTitle = "Content Controls For Review";
        }
        else if (this.state && this.state.listTitle === "document-draft") {
            listTitle = "Document Drafts";
        }
        else if (this.state && this.state.listTitle === "document-awaiting_review") {
            listTitle = "Documents Awaiting Review";
        }
        else if (this.state && this.state.listTitle === "document-for_review") {
            listTitle = "Documents For Review";
        }

        return (
            <div className="opalContent dashboard-Content">
                <h1 className="page-Title">Overview</h1>
                <div className="ms-Grid" dir="ltr">
                    <div className="dashboard-Items">
                        <Can do="read" on="control">
                            <DashboardItem type="contentcontrol" onItemClick={(i) => { this.redirectDashboard(i); }}></DashboardItem>
                        </Can>
                        <Can do="read" on="template">
                            <DashboardItem type="template" onItemClick={(i) => { this.redirectDashboard(i); }}></DashboardItem>
                        </Can>
                        <Can do="read" on="document">
                            <DashboardItem type="document" onItemClick={(i) => { this.redirectDashboard(i); }}></DashboardItem>
                        </Can>
                    </div>
                    {this.state && this.state.listData === null &&
                        <div className="dashboard-EmptyList">
                            <span>Select an option above to view details</span>
                        </div>
                    }
                    {this.state && this.state.listData &&
                        <div className="dashboard-list">
                            <h3>{listTitle}</h3>
                            <OpalTable
                                listData={this.state && this.state.listData}
                                listColumns={this.state && this.state.listColumns}
                                allowPaging={true}
                                pageSettings={{
                                    pageCount: 5,
                                    pageSizes: true
                                }}
                            />
                        </div>
                    }
                </div>

            </div>
        );
    }
}
