import {Log, Util} from "@voxfp/opal_ui_common";
import {SmartRepeat} from "../models/smartRepeat";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";

export class SmartRepeatService {

    getRevision(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.SmartRepeat, id, null, true).then((smartRepeat: SmartRepeat) => {
                resolve(smartRepeat);
            }).catch(error => {   
                Log.error("SmartRepeatService:getSmartRepeat", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    deleteRevision(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.removeEntity(OpalEntity.SmartRepeat, id)
            .then((feedback) => {
                resolve(feedback);
            }).catch(error => {            
                Log.error("SmartRepeatService:deleteRevision with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    updateRevisionState(id, status) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.WorkflowEvent, {"the_action": status}, id)
            .then((feedback) => {
                resolve(feedback);
            }).catch(error => {            
                Log.error("SmartRepeatRevisionsService:updateRevisionState with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

}
