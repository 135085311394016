import {Model} from "@voxfp/opal_ui_common";
import { CurrentRevision } from "./currentRevision";

export class SmartCondition extends Model<SmartCondition> {

    playbookId: number;   
    playbook: string; 
    name: string;
    friendlyName: string;
    updatedAt: any;
    description: string;
    expression: any;
    contentControlRevisionIdIfTrue: number;
    contentControlRevisionIdIfFalse?: number;
    label?: string;
    id?: number;
    currentRevision?: CurrentRevision;

    constructor(playbookId?: number, playbook?: string, name?: string, friendlyName?: string, updatedAt?: any, description?: string, expression?: any, contentControlRevisionIdIfTrue?: number, contentControlRevisionIdIfFalse?: number, label?: string, id?: number, currentRevision?: CurrentRevision) {
        super(SmartCondition);
        this.id = id;
        this.playbookId = playbookId;
        this.playbook = playbook;
        this.label = label;
        this.name = name;
        this.friendlyName = friendlyName;
        this.updatedAt = updatedAt;
        this.description = description;
        this.expression = expression;
        this.contentControlRevisionIdIfTrue = contentControlRevisionIdIfTrue;
        this.contentControlRevisionIdIfFalse = contentControlRevisionIdIfFalse;
        this.currentRevision = currentRevision;
    }
}
