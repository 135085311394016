import {Model} from "@voxfp/opal_ui_common";

export class Documents extends Model<Documents> {

    id?: number;
    name?: string;
    author?: string;
    canInterview?: string;
    updatedAt?: Date;
    templateName?: string;
    templateFamilyName?: string;
    workflowState?: string;
    workflowEvents?: any;

    constructor(id?: number, name?: string, author?: string, canInterview?: string, updatedAt?: Date, templateName?: string, templateFamilyName?: string, workflowState?: string, workflowEvents?: any) {
        super(Documents);
        this.id = id;
        this.name = name;
        this.author = author;
        this.canInterview = canInterview;
        this.updatedAt = updatedAt;
        this.templateName = templateName;
        this.templateFamilyName = templateFamilyName;
        this.workflowState = workflowState;
        this.workflowEvents = workflowEvents;
    }
}
