import ReactDOM from 'react-dom';
import './assets/styles/main.scss';
import App from './App';

declare global {
    interface Window { ENV: any; }
}

window.ENV = window.ENV || {};

ReactDOM.render(<App />, document.getElementById('container'));
