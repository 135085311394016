import {Model} from "@voxfp/opal_ui_common";
import {Revision} from "./revision";
import {SmartChartType} from "./smartChartType";
import {Playbook} from "./playbook";

export class SmartChart extends Model<SmartChart> {
    id?: number;
    label?: string;
    friendlyName?: string;
    smartChartType: SmartChartType;
    playbook: Playbook;
    updatedAt?: any;
    revision?: Revision;

    constructor(id?: number, label?: string, friendlyName?: string, smartChartType?: SmartChartType, playbook?: Playbook, updatedAt?: any, revision?: Revision) {
        super(SmartChart);
        this.id = id;
        this.label = label;
        this.friendlyName = friendlyName;
        this.smartChartType = smartChartType;
        this.playbook = playbook;
        this.updatedAt = updatedAt;
        this.smartChartType = smartChartType;
        this.revision = revision;
    }
}
