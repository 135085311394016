import {Log, Util} from "@voxfp/opal_ui_common";
import {TemplateRevisions} from "../models/templateRevisions";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";

export class TemplateRevisionService {

    getRevision(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.TemplateRevision, id, null, true)
            .then((TemplateRevision: TemplateRevisions) => {
                resolve(TemplateRevision);
            }).catch(error => {            
                Log.error("TemplateRevisionService:getRevision with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    deleteRevision(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.removeEntity(OpalEntity.TemplateRevision, id)
            .then((feedback) => {
                resolve(feedback);
            }).catch(error => {            
                Log.error("TemplateRevisionService:deleteRevision with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    updateRevisionState(id, status) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.TemplateWorkflowEvent, {"the_action": status}, id)
            .then((feedback) => {
                resolve(feedback);
            }).catch(error => {            
                Log.error("TemplateRevisionService:updateRevisionState with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }
}
