import {Model} from "@voxfp/opal_ui_common";

export class TemplateFamily extends Model<TemplateFamily> {

    name: string;
    description: string;

    constructor(name?: string, description?: string) {
        super(TemplateFamily);
        this.name = name;
        this.description = description;
    }
}
