import {Log, Util} from "@voxfp/opal_ui_common";
import {Download} from "../models/downloads";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";

export class DownloadService {

    fileDownload(type, id, fileType) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.downloadEntity(OpalEntity.FileDownload, null, {type, id, fileType})
            .then((download: Download) => {
                this.downloadBase64File(download.documentContents, download.documentName, fileType);
                resolve(download);
            }).catch(error => {            
                Log.error("DownloadService:fileDownload with id=" +  id, error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    downloadBase64File(contentBase64, fileName, fileType) {
        const linkSource = 'data:application/' + fileType + ';base64,' + contentBase64;
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
    
        downloadLink.href = linkSource;
        downloadLink.target = '_self';
        downloadLink.download = fileName;
        downloadLink.click(); 
        document.body.removeChild(downloadLink);
    }

    downloadFile(fileName, data, fileType) {
        const linkSource = 'data:text/' + fileType + ';chartset=UTF-8,' + encodeURIComponent(data);
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        fileName = fileName + "." + fileType;
        downloadLink.href = linkSource;
        downloadLink.target = '_self';
        downloadLink.download = fileName;
        downloadLink.click(); 
        document.body.removeChild(downloadLink);
    }

}
