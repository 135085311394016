import {Log, Util} from "@voxfp/opal_ui_common";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";

export class ContentControlService {

    fetchContentControlRevisionsByTypeFromPlaybook(playbookId, type) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.PlaybookContentControlRevisionsByType, {id: playbookId, type: type}, null, true)
            .then((contentControlRevisions: any) => {
                let contentControlRevisionList = Util.orderOptions(contentControlRevisions, 'id', 'label');
                resolve(contentControlRevisionList);
            }).catch(error => {    
                Log.error("PlaybookService:fetchContentControlRevisionsByTypeFromPlaybook", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    
}
