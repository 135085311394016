import {Model} from "@voxfp/opal_ui_common";
import {Template} from "./template";
import { TemplateFamily } from "./templateFamily";
import { WorkflowEvents } from "./workflowEvents";

export class TemplateRevisions extends Model<TemplateRevisions> {

    version?: number;
    documentsCount?: number;
    workflowState?: string;
    updatedAt?: Date;
    author?: string;
    template?: Template;
    templateFamily?: TemplateFamily;
    workflowEvents?: WorkflowEvents;

    constructor(version?: number, documentsCount?: number, workflowState?: string, updatedAt?: Date, author?: string, template?: Template, templateFamily?: TemplateFamily, workflowEvents?: WorkflowEvents) {
        super(TemplateRevisions);
        this.version = version;
        this.documentsCount = documentsCount;
        this.workflowState = workflowState;
        this.updatedAt = updatedAt;
        this.author = author;
        this.template = template;
        this.templateFamily = templateFamily;
        this.workflowEvents = workflowEvents;
    }
}
