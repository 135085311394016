import {TemplateFamily} from "../models/templateFamily";
import {EntityApi, HttpApi} from "@voxfp/opal_ui_common";
import { TemplateRevisions } from "../models/templateRevisions";
import { ContentControlRevisions } from "../models/contentControlRevisions";
import { Download } from "../models/downloads";
import {SmartToken} from '../models/smartToken';
import {SmartChart} from '../models/smartChart';
import {SmartSwitch} from '../models/smartSwitch';
import {SmartCondition} from '../models/smartCondition';
import { SmartTemplate } from "../models/smartTemplate";
import { SmartRepeat } from "../models/smartRepeat";
import { Documents } from "../models/documents";

export class ApiArguments {
}

export class TemplateFamilyApi extends EntityApi<TemplateFamily> {
    constructor() {
        super({
            url: '/template_families', 
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new TemplateFamily()
        });
    }
}

export class DashboardTemplateSummaryApi extends EntityApi<any> {
    constructor() {
        super({
            url: '/dashboard/template_revisions', 
            isJSON: true, 
            dataIsQueryParams: false
        });
    }
}

export class DashboardDocumentSummaryApi extends EntityApi<any> {
    constructor() {
        super({
            url: '/dashboard/documents', 
            isJSON: true, 
            dataIsQueryParams: false
        });
    }
}

export class DashboardContentControlSummaryApi extends EntityApi<any> {
    constructor() {
        super({
            url: '/dashboard/content_control_revisions', 
            isJSON: true, 
            dataIsQueryParams: false
        });
    }
}

export class TemplateRevisionsByTypeApi extends EntityApi<TemplateRevisions> {
    constructor(args) {
        super({
            url: '/template_revisions/in_progress?type=' + args,
            isJSON: true, 
            dataIsQueryParams: false,
            modelClass: new TemplateRevisions()
        });
    }
}

export class ContentControlRevisionsByTypeApi extends EntityApi<ContentControlRevisions> {
    constructor(args) {
        super({
            url: '/content_control_revisions/in_progress?summary_type=' + args,
            isJSON: true, 
            dataIsQueryParams: false,
            modelClass: new ContentControlRevisions()
        });
    }
}

export class DocumentsByTypeApi extends EntityApi<Documents> {
    constructor(args) {
        super({
            url: '/documents/in_progress?summary_type=' + args,
            isJSON: true, 
            dataIsQueryParams: false,
            modelClass: new Documents()
        });
    }
}

export class PlaybookContentControlRevisionsByTypeApi extends EntityApi<ContentControlRevisions> {
    constructor(args) {
        super({
            url: '/playbooks/' + args.id + '/content_control_revisions?type=' + args.type, 
            isJSON: true, 
            dataIsQueryParams: false,
            modelClass: new ContentControlRevisions()
        });
    }
}

export class TemplateRevisionApi extends EntityApi<TemplateRevisions> {
    constructor() {
        super({
            url: '/template_revisions/',
            isJSON: true, 
            dataIsQueryParams: false,
            modelClass: new TemplateRevisions()
        });
    }
}

export class DocumentsApi extends EntityApi<Documents> {
    constructor() {
        super({
            url: '/documents/',
            isJSON: true, 
            dataIsQueryParams: false,
            modelClass: new Documents()
        });
    }
}

export class SmartTokenApi extends EntityApi<SmartToken> {
    constructor() {
        super({
            url: '/smart_tokens',
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new SmartToken()});
    }
}

export class SmartChartApi extends EntityApi<SmartChart> {
    constructor() {
        super({
            url: '/smart_charts',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new SmartChart()});
    }
}

export class SmartSwitchApi extends EntityApi<SmartSwitch> {
    constructor() {
        super({
            url: '/smart_switches',
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new SmartSwitch()});
    }
}

export class SmartConditionApi extends EntityApi<SmartCondition> {
    constructor() {
        super({
            url: '/smart_conditions',
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new SmartCondition()});
    }
}

export class SmartTemplateApi extends EntityApi<SmartTemplate> {
    constructor() {
        super({
            url: '/smart_templates',
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new SmartTemplate()});
    }
}

export class SmartRepeatApi extends EntityApi<SmartRepeat> {
    constructor() {
        super({
            url: '/smart_repeats',
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new SmartRepeat()});
    }
}

export class WorkflowEventApi extends EntityApi<any> {
    constructor(args) {
        super({
            url: '/content_control_revisions/' + args + '/events',
            isJSON: true, 
            dataIsQueryParams: false
        });
    }
}

export class TemplateWorkflowEventApi extends EntityApi<any> {
    constructor(args) {
        super({
            url: '/template_revisions/' + args + '/events',
            isJSON: true, 
            dataIsQueryParams: false
        });
    }
}

export class DocumentWorkflowEventApi extends EntityApi<any> {
    constructor(args) {
        super({
            url: '/documents/' + args + '/events',
            isJSON: true, 
            dataIsQueryParams: false
        });
    }
}

export class FileDownloadApi extends EntityApi<Download> {
    constructor(args) {
        super({
            url: '/' + args.type + '/' + args.id + '/download?filetype=' + args.fileType, 
            modelClass: new Download()
        });
    }
}

export class LoginApi {
    public static login = new HttpApi().login;
    public static checkLogin = new HttpApi().checkLogin;
}

export class PermissionsApi {
    public static getPermissions = new HttpApi().getPermissions;
}

export class UserApi {
    public static getUser = new HttpApi().getUser;
}
