import * as React from 'react';
import Cookies from 'universal-cookie';
import {PrimaryButton, TextField, Spinner, Dialog, DialogFooter, DialogType, MessageBar, MessageBarType} from 'office-ui-fabric-react';
import {Log, OpalStoreService} from "@voxfp/opal_ui_common";
import {LoginApi, PermissionsApi, UserApi} from '../services/http';

export interface AppProps {
    history?: any;
    onLoginLoad?: any;
}

export interface AppState {
    username: string | undefined;
    password: string | undefined;
    loginChecking: boolean;
    hideLoginError: boolean;
    loggedIn: boolean;
}

export class Login extends React.Component<AppProps, AppState> {

    constructor(props: any, context: any) {
        super(props, context);
        this.state = this.initializeState();
    }

    initializeState() {
        return {
            username: '',
            password: '',
            loginChecking: false,
            hideLoginError: true,
            loggedIn: null
        };
    }

    componentDidMount() {
        if (this.props.onLoginLoad) {
            this.props.onLoginLoad(false);
        }
        const cookies = new Cookies();
        cookies.remove('authToken');
        cookies.remove('author');
        
        OpalStoreService.setAuthToken('');
        OpalStoreService.setProperty('author', '');
    }

    login() {
        return new Promise((resolve, reject) => {
            this.setState({
                loginChecking: true
            });
            LoginApi.login({
                auth: {
                    login: this.state.username,
                    password: this.state.password
                }
            }).then((result: any) => {
                const cookies = new Cookies();
                if (window.ENV.NODE_ENV !== 'production') {
                    cookies.set('authToken', result.jwt);
                    cookies.set('author', this.state.username);
                }
                else {
                    cookies.set('authToken', result.jwt, { domain: '.dev.fundsaxis.org' });
                    cookies.set('author', this.state.username, { domain: '.dev.fundsaxis.org' });
                }
                OpalStoreService.setAuthToken(result.jwt);
                OpalStoreService.setProperty('author', this.state.username);
                
                this.setState({ loggedIn: true });

                resolve(result);

                this.getUser().then(() => {
                    this.props.onLoginLoad(true);
                    this.props.history.push('/dashboard');
                });
            }).catch((err: any) => {            
                this.setState({
                    hideLoginError: false,
                    loginChecking: false,
                    loggedIn: false
                });
                Log.error(err);
                reject(err);
            });

        });        
    }

    getUser() {
        return new Promise((resolve) => {
            const cookies = new Cookies();
            let permissionJSON = [];
            UserApi.getUser().then(userProfile => {
                let userDetails = userProfile.data.user;
                if (window.ENV.NODE_ENV !== 'production') {
                    cookies.set('user', userDetails);
                }
                else {
                    cookies.set('user', userDetails, { domain: '.dev.fundsaxis.org' });
                }
                OpalStoreService.setProperty('user', userDetails);
            }).catch((error) => {
                Log.error(error);
            });
            PermissionsApi.getPermissions().then(permissions => {
                permissions.data.forEach(item => {
                    permissionJSON.push(item);
                });
                let permission = JSON.stringify(permissionJSON);
                if (window.ENV.NODE_ENV !== 'production') {
                    cookies.set('permissions', permission);
                }
                else {
                    cookies.set('permissions', permission, { domain: '.dev.fundsaxis.org' });
                }
                OpalStoreService.setProperty('permissions', permissionJSON);
                resolve(permissionJSON);
            }).catch((error) => {
                Log.error(error);
            });
        });
    }

    setUsername(value: string | undefined) {
        this.setState({
            username: value
        });        
    }

    setPassword(value: string | undefined) {
        this.setState({
            password: value
        });        
    }

    closeDialogModal = () => {
        this.setState({ hideLoginError: true });
    }

    handleKeyDown(event: any) {
        if (event.key === 'Enter') {
            this.login().catch(err => { Log.error(err); });
        }
    }

    render() {

        return (
            <div className='login-Page'>
                <div className='login-Section'>
                    <section className='login-Header'>
                        <img width='200' src={require('../assets/images/logo.png').default} alt='Welcome' title='Opal Dashboard' />
                    </section>
                    <div className='login-Form'>
                        <TextField
                            underlined
                            placeholder='Username'
                            name={'username'}
                            onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => this.setUsername(value)}
                            onKeyPress={this.handleKeyDown.bind(this)}
                            type={'text'}
                            value={this.state.username}
                        />
                        <br/>
                        <TextField
                            underlined
                            placeholder='Password'
                            name={'password'}
                            onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => this.setPassword(value)}
                            onKeyPress={this.handleKeyDown.bind(this)}
                            type={'password'}
                            value={this.state.password}
                        />
                        <br/>
                        <br/>
                        <div className="login-Button">
                            {this.state && !this.state.loginChecking ?
                                <PrimaryButton 
                                    styles={{flexContainer: { flexDirection: 'row-reverse' }}} 
                                    text='Login' 
                                    iconProps={{ iconName: "NavigateForward" }} 
                                    onClick={ 
                                        () => { this.login().catch(err => { Log.error(err); }); }
                                    }
                                />
                                :
                                <PrimaryButton>
                                    <Spinner />
                                </PrimaryButton>
                            }
                        </div>
                    </div>

                    <Dialog
                        hidden={this.state.hideLoginError}
                        onDismiss={ this.closeDialogModal.bind(this) }
                        dialogContentProps={ {
                            type: DialogType.normal,
                            title: 'Login Error'
                        } }
                        modalProps={ {
                            titleAriaId: 'ErrorLogin',
                            isBlocking: false
                        } }
                    >
                        <MessageBar messageBarType={MessageBarType.error}>
                            The E-mail or Password you entered is incorrect. Please check and try again.
                        </MessageBar>
                        <DialogFooter>
                            <PrimaryButton className="dismiss" onClick={ this.closeDialogModal.bind(this) } text='Ok' />
                        </DialogFooter>                    
                    </Dialog>

                </div>
            </div>
        );
    }
}
