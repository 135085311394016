import {Model} from "@voxfp/opal_ui_common";

export class ContentControlRevisions extends Model<ContentControlRevisions> {

    id?: number;
    label?: string;
    friendlyName?: string;
    playbook?: string;
    version?: number;
    updatedAt?: any;
    author?: string;
    workflowState?: string;
    templatesCount?: any;
    details?: any;

    constructor(id?: number, label?: string, friendlyName?: string, playbook?: string, version?: number, updatedAt?: any, author?: string, workflowState?: string, templatesCount?: any, details?: any) {
        super(ContentControlRevisions);
        this.id = id;
        this.label = label;
        this.friendlyName = friendlyName;
        this.playbook = playbook;
        this.version = version;
        this.updatedAt = updatedAt;
        this.author = author;
        this.workflowState = workflowState;
        this.templatesCount = templatesCount === undefined ? 0 : templatesCount;
        this.details = details;
    }
}
