import {Log, Util} from "@voxfp/opal_ui_common";
import {SmartChart} from "../models/smartChart";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";

export class SmartChartService {
    getRevision(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.SmartChart, id, null, true).then((smartChart: SmartChart) => {
                resolve(smartChart);
            }).catch(error => {
                Log.error("SmartChartService:getSmartChart", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    updateRevisionState(id, status) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.WorkflowEvent, {"the_action": status}, id)
                .then((feedback) => {
                    resolve(feedback);
                }).catch(error => {
                    Log.error("SmartChartRevisionsService:updateRevisionState with id=" +  id, error);
                    reject(CommonUtils.FormatError(error));
                }).finally(() => {
                    Util.stopSpinning();
                });
        });
    }
}
