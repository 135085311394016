import {Model} from "@voxfp/opal_ui_common";
import {SwitchValue} from "./SwitchValue";
import { CurrentRevision } from "./currentRevision";

export class SmartSwitch extends Model<SmartSwitch> {

    playbookId: number;    
    playbook: string;  
    name: string;
    description: string;
    updatedAt: any;
    switch: Array<SwitchValue>;
    defaultContentControlRevisionId: number;
    caseContentControlRevisionId?: number;
    label?: string;
    friendlyName?: string;
    id?: number;
    currentRevision?: CurrentRevision;

    constructor(playbookId?: number, playbook?: string, name?: string, description?: string, updatedAt?: any, switchValue?: Array<SwitchValue>, defaultContentControlRevisionId?: number, caseContentControlRevisionId?: number, label?: string, friendlyName?: string, id?: number, currentRevision?: CurrentRevision) {
        super(SmartSwitch);
        this.id = id;
        this.playbookId = playbookId;
        this.playbook = playbook;
        this.label = label;
        this.friendlyName = friendlyName;
        this.name = name;
        this.description = description;
        this.updatedAt = updatedAt;
        this.switch = switchValue;
        this.defaultContentControlRevisionId = defaultContentControlRevisionId;
        this.caseContentControlRevisionId = caseContentControlRevisionId;
        this.currentRevision = currentRevision;
    }
}
