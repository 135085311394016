import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { DashboardService } from '../../services/dashboardService';
import { MessageBarType, DefaultButton, Label, IContextualMenuItem, Breadcrumb, IBreadcrumbItem } from 'office-ui-fabric-react';
import { Util, Toast } from "@voxfp/opal_ui_common";
import { DownloadService } from '../../services/downloadService';
import { TemplateRevisionService } from '../../services/templateRevisions';
import WorkflowButton from '../workflowButtons/workflowButton';

export interface AppProps {
    title?: string;
    config?: object;
    history?: any;
}

export interface AppState {
    id: number;
    title: string;
    playbook: string;
    templateFamily: string;
    author: string;
    version: any;
    documentCount: any;
    lastUpdate: any;
    status: string;
    workflowEvents: Array<any>;
}

export class TemplateDetail extends React.Component<AppProps & RouteComponentProps, AppState>  {

    dashboardService: DashboardService = new DashboardService();
    downloadService: DownloadService = new DownloadService();
    TemplateRevisionService: TemplateRevisionService = new TemplateRevisionService();

    public breadcrumbList: Array<IBreadcrumbItem> = [];

    constructor(props, state) {
        super(props, state);

        this.breadcrumbList = [
            { text: 'Dashboard', key: 'Dashboard', onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, _item?: IBreadcrumbItem) => window.history.back() },
            { text: "Details", key: 'f1', href: "#" + this.props.location.pathname, isCurrentItem: true }
        ];
    }

    componentDidMount() {
        this.setState(this.reset());
        const query = new URLSearchParams(this.props.match.params);
        const id = Number(query.get('id'));
        this.setState({
            id: id
        });

        this.fetchTemplate(id);
    }

    reset() {
        return ({
            id: null,
            title: "",
            playbook: "",
            templateFamily: "",
            author: "",
            version: "",
            documentCount: "",
            lastUpdate: "",
            status: "",
            workflowEvents: []
        });
    }

    fetchTemplate(id) {
        this.TemplateRevisionService.getRevision(id)
            .then((Template: any) => {
                this.setState({
                    title: Template.template.name,
                    playbook: Template.templateFamily.playbook.name,
                    templateFamily: Template.templateFamily.name,
                    author: Template.author,
                    version: Template.version,
                    documentCount: Template.documentsCount > 0 ? Template.documentsCount : "0",
                    lastUpdate: Template.updatedAt,
                    status: Template.workflowState,
                    workflowEvents: Template.workflowEvents
                });
            })
            .catch((error) => {
                Util.showToast(new Toast('Error fetching template. ' + error, MessageBarType.error));
            });
    }

    updateRevisionState(id, state) {
        this.TemplateRevisionService.updateRevisionState(id, state).then((_data) => {
            Util.showToast(new Toast('Template workflow set to ' + state + '.', MessageBarType.success));
            this.fetchTemplate(id);
            if (state === "Approve") {
                window.history.back();
            }
        })
            .catch((error) => {
                Util.showToast(new Toast('Error setting workflow. ' + error, MessageBarType.error));
            });
    }

    render() {

        return (
            <div className="opalContent dashboard-Content">
                <h1 className="mt0 mb05">Details {this.state && this.state.title ? "- " + this.state.title : ""}</h1>
                    <div className={"breadcrumb"}>
                        <Breadcrumb
                            items={this.breadcrumbList}
                            maxDisplayedItems={3}
                            ariaLabel="Breadcrumb with items rendered as links"
                            overflowAriaLabel="More links"
                        />
                    </div>
                {this.state && this.state.id && this.state.playbook !== "" &&
                <div>
                    
                    <div className="ms-Grid-row mt2">
                        <div className="ms-Grid-col ms-sm12 ms-xl5">
                        <div className="ms-Grid-row mb05">
                                <div className="ms-Grid-col ms-sm5">
                                    <Label className="p0">Name</Label>
                                </div>
                                <div className="ms-Grid-col ms-sm7">
                                    {this.state.title}
                                </div>
                            </div>
                            <div className="ms-Grid-row mb05">
                                <div className="ms-Grid-col ms-sm5">
                                    <Label className="p0">Playbook</Label>
                                </div>
                                <div className="ms-Grid-col ms-sm7">
                                    {this.state.playbook}
                                </div>
                            </div>
                            <div className="ms-Grid-row mb05">
                                <div className="ms-Grid-col ms-sm5">
                                    <Label className="p0">Template Family</Label>
                                </div>
                                <div className="ms-Grid-col ms-sm7">
                                    {this.state.templateFamily}
                                </div>
                            </div>
                            <div className="ms-Grid-row mb05">
                                <div className="ms-Grid-col ms-sm5">
                                    <Label className="p0">Author</Label>
                                </div>
                                <div className="ms-Grid-col ms-sm7">
                                    {this.state.author}
                                </div>
                            </div>
                            <div className="ms-Grid-row mb05">
                                <div className="ms-Grid-col ms-sm5">
                                    <Label className="p0">Version</Label>
                                </div>
                                <div className="ms-Grid-col ms-sm7">
                                    {this.state.version}
                                </div>
                            </div>
                            <div className="ms-Grid-row mb05">
                                <div className="ms-Grid-col ms-sm5">
                                    <Label className="p0">Last Update</Label>
                                </div>
                                <div className="ms-Grid-col ms-sm7">
                                    {this.state.lastUpdate}
                                </div>
                            </div>
                            <div className="ms-Grid-row mb05">
                                <div className="ms-Grid-col ms-sm5">
                                    <Label className="p0">Document Count</Label>
                                </div>
                                <div className="ms-Grid-col ms-sm7">
                                    {this.state.documentCount}
                                </div>
                            </div>

                            <div className="ms-Grid-row mb2">
                                <div className="ms-Grid-col ms-sm5">
                                    <Label className="p0">Status</Label>
                                </div>
                                <div className="ms-Grid-col ms-sm7">
                                    {this.state.status}
                                </div>
                            </div>

                            {this.state && this.state.workflowEvents.map(
                                (item, i) => {
                                    return (
                                        <WorkflowButton
                                            key={i}
                                            workflowItem={item}
                                            onHandleClick={(id, name) => this.updateRevisionState(id, name)}
                                            id={this.state.id}
                                        />
                                    );
                                })
                            }

                            <DefaultButton
                                iconProps={{ iconName: "Download" }}
                                style={{ width: "100%" }}
                                title={"Download template"}
                                text="Download template"
                                menuProps={{
                                    isBeakVisible: false,
                                    items: [
                                        {
                                            key: 'asDOCX',
                                            text: 'As DOCX file',
                                            iconProps: { iconName: 'WordDocument' },
                                            onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, _item?: IContextualMenuItem) => {
                                                this.downloadService.fileDownload("template_revisions", this.state.id, "docx");
                                            }
                                        },
                                        {
                                            key: 'asPDF',
                                            text: 'As PDF file',
                                            iconProps: { iconName: 'PDF' },
                                            onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, _item?: IContextualMenuItem) => {
                                                this.downloadService.fileDownload("template_revisions", this.state.id, "pdf");
                                            }
                                        }
                                    ]
                                }}
                            />
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default TemplateDetail;
