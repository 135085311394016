import {Model} from "@voxfp/opal_ui_common";
import {Template} from "./template";
import { CurrentRevision } from "./currentRevision";

export class SmartTemplate extends Model<SmartTemplate> {

    id?: number;
    label?: string;
    templateId?: number;
    templatableId?: number;
    friendlyName?: string;
    updatedAt?: Date;
    ooxml?: string;
    playbook?: string;
    playbookId?: number;
    template?: Template;
    templateRevision?: any;
    currentRevision?: CurrentRevision;

    constructor(id?: number, label?: string, templateId?: number, templatableId?: number, friendlyName?: string, updatedAt?: Date, ooxml?: string, playbook?: string, playbookId?: number, template?: Template, templateRevision?: any, currentRevision?: CurrentRevision) {
        super(SmartTemplate);
        this.id = id;
        this.label = label;
        this.templateId = templateId;
        this.templatableId = templatableId;
        this.friendlyName = friendlyName;
        this.updatedAt = updatedAt;
        this.ooxml = ooxml;
        this.playbook = playbook;
        this.playbookId = playbookId;
        this.template = template;
        this.templateRevision = templateRevision;
        this.currentRevision = currentRevision;
    }
}
