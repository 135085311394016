import {Log, Util} from "@voxfp/opal_ui_common";
import {SmartSwitch} from "../models/smartSwitch";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";

export class SmartSwitchService {

    getRevision(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.SmartSwitch, id, null, true).then((smartSwitch: SmartSwitch) => {
                resolve(smartSwitch);
            }).catch(error => {   
                Log.error("SmartSwitchService:getSmartSwitch", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    deleteRevision(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.removeEntity(OpalEntity.SmartSwitch, id)
            .then((feedback) => {
                resolve(feedback);
            }).catch(error => {            
                Log.error("SmartSwitchService:deleteRevision with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    updateRevisionState(id, status) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.WorkflowEvent, {"the_action": status}, id)
            .then((feedback) => {
                resolve(feedback);
            }).catch(error => {            
                Log.error("SmartSwitchRevisionsService:updateRevisionState with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

}
