import {Log, Util} from "@voxfp/opal_ui_common";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";
import { Documents } from "../models/documents";

export class DocumentService {

    getDocument(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.Documents, id, null, true).then((document: Documents) => {
                resolve(document);
            }).catch(error => {   
                Log.error("DocumentService:getDocument", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    updateDocumentState(id, status) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.DocumentWorkflowEvent, {"the_action": status}, id)
            .then((feedback) => {
                resolve(feedback);
            }).catch(error => {            
                Log.error("DocumentService:updateDocumentState with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

}
